import * as React from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'
import './styles.scss'

const PaginationNavigation = ({
    pagination,
    pageNumber,
    setCurrentPage,
    isCurrentPage
}) => {
    const changePage = (targetPageNumber) => {
        //scrollTo('#searchTop')
        setCurrentPage(targetPageNumber)
    }

    const prevPage = (currentPageNumber) => {
        //scrollTo('#searchTop')
        setCurrentPage(currentPageNumber - 1)
    }

    const nextPage = (currentPageNumber) => {
        //scrollTo('#searchTop')
        setCurrentPage(currentPageNumber + 1)
    }

    return (
        <div className={'c-pagination-nav'}>
            <button
                className="c-pagination-nav__item c-pagination-nav__item--link c-pagination-nav__item--back"
                onClick={() => prevPage(isCurrentPage)}
                disabled={isCurrentPage === 1}
            >
                <span>Back</span>
            </button>

            <div className="c-pagination-nav__inner">
                {pagination.map((node, index) => {
                    if (node === pageNumber) {
                        return (
                            <div
                                key={index}
                                className="c-pagination-nav__item c-pagination-nav__item--current"
                            >
                                {node}
                            </div>
                        )
                    } else {
                        return (
                            <button
                                key={index}
                                className="c-pagination-nav__item c-pagination-nav__item--link"
                                onClick={() => changePage(node)}
                            >
                                {node}
                            </button>
                        )
                    }
                })}
            </div>

            <button
                className="c-pagination-nav__item c-pagination-nav__item--link c-pagination-nav__item--next"
                onClick={() => nextPage(isCurrentPage)}
                disabled={isCurrentPage === pagination.length}
            >
                <span>Next</span>
            </button>
        </div>
    )
}

PaginationNavigation.propTypes = {
    pagination: PropTypes.array,
    pageNumber: PropTypes.number,
    setCurrentPage: PropTypes.func,
    isCurrentPage: PropTypes.number
}

PaginationNavigation.defaultProps = {
    pagination: []
}

export default PaginationNavigation
